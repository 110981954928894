<template>
  <div class="prev-container" v-show="isShow">
    <div class="mask"></div>
    <div class="indicator">
      <a-icon type="reload" @click="operate('rotate')" />
      <a-icon type="close-circle" @click="close" />
    </div>
    <div :class="['prev-next', 'left', { 'pointer-allowd': isPrev }]" @click="operate('left')">
      <a-icon type="left-circle" size="36" color="black" />
    </div>
    <div :class="['prev-next', 'right', { 'pointer-allowd': isNext }]" @click="operate('right')">
      <a-icon type="right-circle" size="36" color="black" />
    </div>
    <div :class="['img-wrapper']" @click="close">
      <img :src="imgPaths[imgIdx]" :style="imgStyle" alt="">
    </div>
    <div class="footer-img">
      <img
          v-for="(img, index) in imgPaths"
          :key="index"
          :class="imgIdx === index ? 'border-red' : ''"
          :src="img"
          alt=""
          @click="handleChangeCurrentImg(index)"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrevImg',
  components: {
  },
  data() {
    return {
      isShow: false,
      imgIdx: 0,
      imgPaths: [],
      transform: {
        rotate: 0,
        scale: 1
      },
      enterAniClass: '',
      showCloseAnimation: false,
      tempZoom: 1,
    }
  },
  computed: {
    imgStyle() {
      let { rotate, scale } = this.transform
      return {
        transform: `scale(${scale}) rotate(${rotate}deg)`
      }
    },
    isNext() {
      return this.imgIdx !== this.imgPaths.length - 1
    },
    isPrev() {
      return this.imgIdx !== 0
    },
  },
  mounted() {
    addEventListener('keydown',(e)=>{
      if(e.key === 'Escape'){
        this.close()
      }
    });
    addEventListener('mousewheel',(e)=>{
      this.onmousewheelMove(e)
    })
    addEventListener('wheel',(e)=>{
      this.onmousewheelMove(e)
    })
  },
  methods: {
    /** 切换图片 */
    handleChangeCurrentImg(index) {
      this.imgIdx = index
    },
    onmousewheelMove(e){
      if (this.transform.scale >= 0.05) {
        this.transform.scale += e.wheelDelta / 3000
      } else {
        this.transform.scale = 0.05
        return false
      }
    },
    open({ imgIdx, imgPaths }) {
      this.transform = {
        rotate: 0,
        scale: 1
      }
      this.isShow = true
      this.imgIdx = imgIdx
      this.imgPaths = imgPaths
    },
    close() {
      console.log('object');
      this.isShow = false
      this.transform = {
        rotate: 0,
        scale: 1
      }
      this.imgPaths = []
      removeEventListener('keydown', this.close)
      removeEventListener('mousewheel', this.close)
    },
    operate(command) {
      if (command === 'rotate') {
        this.transform.rotate += 90
      } else {
        this.transform = {
          rotate: 0,
          scale: 1
        }
        if (command === 'left') {
          if (this.imgIdx === 0) {
            return
          }
          this.imgIdx = this.imgIdx - 1
        } else if (command === 'right') {
          if (this.imgIdx === this.imgPaths.length - 1) {
            return
          }
          this.imgIdx = this.imgIdx + 1
        }

      }
    }
  }
}
</script>

<style lang="scss">
.border-red {
  border: 3px solid red;
}
.footer-img {
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
.prev-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .mask {
    position: absolute;
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .pointer-allowd {
    cursor: pointer !important;
  }

  .prev-next {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .3);
    text-align: center;
    line-height: 50px;
    cursor: not-allowed;

    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    z-index: 2000;
  }
  .left {
    left: 350px;
  }
  .right {
    right: 350px;
  }
  .indicator {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    z-index: 100;

    i {
      padding: 10px;
      background-color: rgba(255, 255, 255, .3);
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .img-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 80%;
      transition: all 0.5s;
    }
  }
}

</style>
