import VueRoute from 'vue-router'
import Vue from 'vue'
import routerMap from './routerMap'
import {judgeCurrentUserIdIsRole} from "@/utils/index"

const routerPush = VueRoute.prototype.push
VueRoute.prototype.push = function push(location) {//防止重新加载相同的路由时控制台报错
    return routerPush.call(this, location).catch(error=> error)
}

Vue.use(VueRoute)

const routes = [
    {
        path:'', name: 'login', meta: { title: "评级后台"},
        component: resolve => require(['@/views/Login/index'], resolve),
    },
    {
        path:'/home',
        name:'home',
        meta:{},
        children: routerMap,
        component: resolve => require(['@/views/Home/index'], resolve)
    }
]

const router = new VueRoute({
    // 配置路由和组件之间的应用关系
    routes,
    mode: 'hash',
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title === undefined ?' 斗泉后台' : to.meta.title
    let token = localStorage.getItem('token')
    let roleAllToPageType = localStorage.getItem('roleAllToPage')
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}

    if (from.path === '/' && !token) {
        if (to.path !== '/') {
            next('/');
        } else {
            next(); // 用户已经在/checkAuctionProduct上，不进行重定向
        }
    } else if (to.path === '/') {
        next()
    } else if (to.path === '/home') {
        if (['ratingProduct', 'all'].includes(judgeCurrentUserIdIsRole()) && roleAllToPageType === 'ratingProduct') {
            next()
        } else {
            next(from.path);
        }
    } else if (to.path === '/checkAuctionProduct') {
        if (['checkProduct', 'all'].includes(judgeCurrentUserIdIsRole()) && roleAllToPageType === 'checkProduct') {
            next()
        } else {
            next(from.path);
        }
    } else {
        if (!token) {
            next('/')
        } else {
            if (['checkProduct', 'all'].includes(judgeCurrentUserIdIsRole()) && roleAllToPageType === 'checkProduct') {
                if (to.path !== '/checkAuctionProduct') {
                    next('/checkAuctionProduct');
                } else {
                    next(); // 用户已经在/checkAuctionProduct上，不进行重定向
                }
            } else if (['ratingProduct', 'all'].includes(judgeCurrentUserIdIsRole()) && roleAllToPageType === 'ratingProduct')  {
                if (to.path === '/checkAuctionProduct') {
                    next('/home');
                } else {
                    next()
                }
            }
        }
    }
});

export default router