import ShowBigImg from "@/components/ShowBigImg/index.vue"
export default {
    install(Vue, options) {

        let PrevImgConstructor = Vue.extend(ShowBigImg)
        let instance = new PrevImgConstructor()
        /* 这里会打印dom */
        // console.log('instance', instance.$mount().$el);// instance <div class="prev-container" style="display: none;">...</div>

        /* 把组件转换成了dom，插入到了body中  */
        document.body.appendChild(instance.$mount().$el)

        let opts = {
            open(imgIdx,imgPaths) {
                instance.open({imgIdx,imgPaths})
            },
            close() {
                instance.close()
            }
        }

        Vue.prototype.$showBigImg = opts
    }
}
