export default [
    {
        path: '/checkAgainListPage',
        name: 'checkAgainListPage',
        meta: { title: "复评鉴定列表" },
        component: resolve => require(['@/views/CheckAgainManage/CheckListPage'], resolve)
    },
    {
        path: '/checkAgainActionPage',
        name: 'checkAgainActionPage',
        meta: { title: "复评鉴定操作"},
        component: resolve => require(['@/views/CheckAgainManage/CheckActionPage/index'], resolve)
    },
]