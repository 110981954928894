import CheckRouter from "@/router/routerMap/CheckRouter"
import SendRouter from "@/router/routerMap/SendRouter"
import SettlementRouter from "@/router/routerMap/SettlementRouter"
import TakeOverRouter from "@/router/routerMap/TakeOverRouter"
import CheckAuctionProduct from "@/router/routerMap/CheckAuctionProduct"
import CheckAgainRouter from "@/router/routerMap/CheckAgainRouter"

export default [
    ...CheckAgainRouter,
    ...SendRouter,
    ...CheckRouter,
    ...SettlementRouter,
    ...TakeOverRouter,
    ...CheckAuctionProduct
]