/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用ant design Message做消息提醒
import { message, Spin } from 'ant-design-vue';
import {getLocalStorage} from "@/utils/index"
//1. 创建新的axios实例，
const service = axios.create({
    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 30 * 1000,
})
// 2.请求拦截器
service.interceptors.request.use(config => {
    let token = getLocalStorage('token') || ''
    let proxy_url = process.env.VUE_APP_BASE_URL
    config.url = proxy_url+config.url;
    config.headers['dqZhandian-token'] = token || ''
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
})

// 3.响应拦截器
service.interceptors.response.use(
    res => {
        if(res.status === 200){
            const { status } = res.data
            if(status === 401 || status === '401'){
                localStorage.removeItem('token')
                setTimeout(async ()=> await this.$router.push('/'),500)
            }
            if(status === 'SUCCESS'|| status === 200 || status === '200'){
                return res.data
            }else if(status === '200' || status === 200){
                return res.data
            }else {
                message.error(res.data.message||'响应异常')
                return res.data
            }
        } else {
            message.error('服务异常，请打开控制台查看详细信息')
            return res;
        }
    },
        error => {
            message.error('服务异常，请打开控制台查看详细信息')
            return error
    }
)
export default service