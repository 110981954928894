var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "prev-container",
    },
    [
      _c("div", { staticClass: "mask" }),
      _c(
        "div",
        { staticClass: "indicator" },
        [
          _c("a-icon", {
            attrs: { type: "reload" },
            on: {
              click: function ($event) {
                return _vm.operate("rotate")
              },
            },
          }),
          _c("a-icon", {
            attrs: { type: "close-circle" },
            on: { click: _vm.close },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: ["prev-next", "left", { "pointer-allowd": _vm.isPrev }],
          on: {
            click: function ($event) {
              return _vm.operate("left")
            },
          },
        },
        [
          _c("a-icon", {
            attrs: { type: "left-circle", size: "36", color: "black" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: ["prev-next", "right", { "pointer-allowd": _vm.isNext }],
          on: {
            click: function ($event) {
              return _vm.operate("right")
            },
          },
        },
        [
          _c("a-icon", {
            attrs: { type: "right-circle", size: "36", color: "black" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          class: ["img-wrapper"],
          style: _vm.sonImgStyle,
          on: { click: _vm.close },
        },
        [
          _c("img", {
            style: _vm.imgStyle,
            attrs: { src: _vm.imgPaths[_vm.imgIdx], alt: "" },
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "footer-img" },
        _vm._l(_vm.imgPaths, function (img, index) {
          return _c("img", {
            key: index,
            class: _vm.imgIdx === index ? "border-red" : "",
            attrs: { src: img, alt: "" },
            on: {
              click: function ($event) {
                return _vm.handleChangeCurrentImg(index)
              },
            },
          })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }