import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import serviceApi from './request'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import CustomComponents from "@/components/CustomComponents"
import VueLazyLoad from "vue-lazyload"

Vue.config.productionTip = false
Vue.prototype.$ServiceApi = serviceApi

Vue.use(Antd)
Vue.use(CustomComponents)
Vue.use(VueLazyLoad, {
  // 设置懒加载时的占位图
  loading: require('../src/assets/product_default_img.png'),
  // 设置图片加载失败时的占位图
  error: require('../src/assets/product_default_img.png'),
  preLoad: 1
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
