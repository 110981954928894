import {CHECK_PRODUCT_BY_USER_ID_LIST_OBJ} from "@/_data"

/** ant 下拉选择过滤 */
export const filterOption = (e, option) => {
    const in_array = option.componentOptions.children
    if(in_array[0].children&&in_array[0].children.length>0){
        const {attrs} = in_array[0].children[1].data
        return (
            attrs.text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0
        );
    }else{
        return (
            in_array[0].text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0
        );
    }
}

/** 获取随机字符串 */
export const randomString = (len) => {
    len = len || 32;
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

/** 图片上传回显返回回显结构---ant
 * @param imgList {Array} 图片的数组
 * */
export const returnAntUploadImgObj = (imgList) => {
    if (!imgList.length) return []
    return imgList.map((item) => {
        return {
            uid: randomString(5),
            name: "image",
            status: "done",
            url: item,
        };
    });
}

/** 对象赋值，用于编辑回显从列表中靠背数据到编辑接口所需的数据
 * @param baseObj {Object}
 * @param complexObj {Object}
 * */
export const updateBaseObjectWithComplex = (baseObj, complexObj) => {
    // 遍历复杂对象的所有属性
    for (let key in complexObj) {
        // 检查该属性是否存在于基础对象中
        if (baseObj.hasOwnProperty(key)) {
            // 如果存在，则将复杂对象中的值赋给基础对象
            baseObj[key] = complexObj[key];
        }
        // 注意：这里没有处理复杂对象的嵌套对象或数组，只处理了一级属性
    }
    // 返回更新后的基础对象（虽然通常我们是在原地修改baseObj，所以这一步是可选的）
    return baseObj;
}


/** 获取缓存 */
export const getLocalStorage = function (name) {
    try {
        let o = JSON.parse(localStorage[name]);
        if (!o || o.expires < Date.now()) {
            return ''
        } else {
            return JSON.parse(o.value)
        }
    } catch (e) {
        // 兼容其他localstorage
        return localStorage[name]
    }
}

/** 设置缓存 */
// 设置LocalStorage过期
export const setLocalStorage = function  (key, value, days) {
    value = JSON.stringify(value);
    // 设置过期原则
    if (!value) {
        localStorage.removeItem(key)
    } else {
        let Days = days || 24; // 以小时为单位，默认24小时
        let exp = new Date();
        localStorage[key] = JSON.stringify({
            value,
            // 和后端协商做了失效时间
            expires: exp.getTime() + Days * 1000*60*60
        })
    }
}

/** 匹配路由 */
export const matchBreads = (keys, array) => {
    if(!array || array.length < 1) return
    let obj
    array.forEach(e => {
        if (e.key === keys[0]) {
            e.children.forEach(el => {
                if (el.key === keys[1]) {
                    obj = el
                }
            })
        }
    })
    return obj
}

/**
 * @param objAttr 需要转化成的对象的对应数组中的属性值
 * @param objValue 需要转化成对象的值所对应数组中的值
 * @param objArr  需要转化的对象数组
 * */
export const objArrMapObj = (objAttr, objValue, objArr) => {
    const obj = {}
    objArr.forEach(el => {
        obj[el[objAttr]] = el[objValue]
    })
    return obj
}

/** 数组对象去重 */
export const uniqueArray = (array, valueAttr) => {
    return array.filter((item, index) => {
        return array.findIndex(obj => obj[valueAttr] === item[valueAttr]) === index;
    });
}

/** 销毁扫码枪时间 */
export const destroyKeyPress = () => {
    document.onkeydown = ''
}
/** 鉴定扫码枪输入事件 */
export const scanCodeWatch = (customCallback) => {
    let code = ''
    let lastTime, nextTime
    let lastCode, nextCode
    document.onkeypress = (e) => {
        if (window.event) {
            nextCode = e.keyCode
        } else if (e.which) {
            nextCode = e.which
        }
        if (nextCode === 13) {
            if (code.length < 3) return
            customCallback(code)
            code = ''
            lastCode = ''
            lastTime = ''
            return
        }
        nextTime = new Date().getTime()
        if (!lastTime && !lastCode) {
            code += String.fromCharCode(nextCode)
        }
        if (lastCode && lastTime && nextTime - lastTime > 30) {
            code = String.fromCharCode(nextCode)
        } else if (lastCode && lastTime) {
            code += String.fromCharCode(nextCode)
        }
        lastCode = nextCode
        lastTime = nextTime
    }
}

/** 计算像素 */
export const getDPI = () => {
    let arrDPI = new Array;
    if (window.screen.deviceXDPI) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        let tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI;
}

//px转毫米

export const pxToMm = (value) => {
    let inch = value / getDPI()[0];
    let c_value = inch * 25.4;
    return c_value;
}

//毫米转px
export const mmToPx = (value) => {
    let inch = value / 25.4;
    let c_value = inch * getDPI()[0];
    return c_value
}

/** 截取字符串斜杠后的数字 */
export const matchGongStr = (str) => {
    if (str.includes('/')) {
        const regex = /\/(\d+)$/;  // 匹配斜杠后的数字
        const match = str.match(regex);
        return match ? match[1] : '';
    } else {
        return str
    }
}

/** 获取网址十位数字 */
export const extractTenDigits = (url) => {
    const regex = /\d{10}/; // 匹配连续十位数字的正则表达式
    const match = url.match(regex); // 使用正则表达式匹配数字
    if (match) {
        return match[0]; // 返回匹配到的连续十位数字
    } else {
        return url;
    }
}

/** 过滤信息 */
export const getValues = (obj) => {
    const keys = Object.keys(obj);
    let arr = []
    arr = keys.map(key => {
        if (obj[key]) {
            return obj[key];
        }
    });
    return arr
}

/** 判断用户是否是什么类型用户 */
export const judgeCurrentUserIdIsRole = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (CHECK_PRODUCT_BY_USER_ID_LIST_OBJ.checkProduct.includes(userInfo.id)) {
        return 'checkProduct'
    } else if (CHECK_PRODUCT_BY_USER_ID_LIST_OBJ.ratingProduct.includes(userInfo.id)) {
        return 'ratingProduct'
    } else {
        return 'all'
    }
}
